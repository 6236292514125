<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'CustomerStories',

    metaInfo() {
      return {
        title: this.$t('section.customer_stories'),
        meta: [
          { name: 'description', content: this.$t('section.customer_stories_description') },
        ],
      };
    },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        'customer-stories',
        'request-demo',
        'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'customer-stories',
      },
    },
  }
</script>
